import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
// MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import HeadsetIcon from '@material-ui/icons/Headset';
import ChatIcon from '@material-ui/icons/Chat';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    card: {
        margin: theme.spacing.unit * 2
    },
    button: {
        marginLeft: "auto",
        margin: theme.spacing.unit,
        padding: "6px",
        verticalAlign: "bottom"
    },
    smallButton: {
        marginLeft: "auto",
        margin: theme.spacing.unit,
        padding: "6px",
        verticalAlign: "bottom",
        fontSize: "10px"
    },
    playerCard: {
        margin: theme.spacing.unit * 2,
        maxWidth: "600px"
    },
    tableRoot: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    dialogBanner: {
        height: "42px",
        width: "100%",
        backgroundColor: "black"
    },
    dialogText: {
        marginTop: "auto",
        marginBottom: "auto",
        color: "#fff"
    },
    dialogSubText: {
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "20px",
        fontSize: "16px",
        color: "#555"
    },
    statCard: {
        backgroundColor: "black"
    },
    greenText: {
        color: "#42AB4E",
        marginBlockEnd: "8px",
        marginBlockStart: "8px"
    },
    whiteText: {
        color: "#fff",
        marginLeft: "20px",
        marginBlockEnd: "8px",
        marginBlockStart: "8px"
    },
    image: {
        backgroundColor: "black"
    }
});

class PlayerRankingsDemo extends Component {

    state = {
        selectedPlayer: null
    }

    render() {

        const { classes } = this.props;

        let rows = [
            {
                name: 'Christian McCaffrey',
                team: 'CAR',
                position: 'RB',
                rank: 1,
                player_rank: 1,
                ht_wt:  '5\' 11", 205 lbs',
                drafted: 'Rd 1 (#8) - 2017',
                experience: '4 years',
                college: 'Stanford',
                bye: 13,
                blurb: 'There have been 109 running back performances that have netted 25-plus half-PPR points over the last two years. Despite missing essentially the whole 2020 season, McCaffrey has accounted for 9.2 percent of them. He\'s somehow managed to score 25-plus points in 10 of his last 19 games, and has scored fewer than 19 fantasy points in just two of them. He\'s basically posting quarterback numbers as a running back. He should be the No. 1 pick in 2021 drafts.',
                link: 'https://www.thefantasyfootballers.com/fantasy/christian-mccaffrey/',
                img: 'https://cloud.thefantasyfootballers.com/images/web-profile/headshots/fdnobg18877.png',
                articles: [
                    {
                        title: 'How to spot a Fantasy Football Winner',
                        link: 'https://www.thefantasyfootballers.com/articles/how-to-spot-a-fantasy-football-league-winner-rbs/'
                    },
                    {
                        title: 'DFS Strategy of the Week',
                        link: 'https://www.thefantasyfootballers.com/dfs/dfs-strategy-the-weekly-schedule-of-players-who-want-to-get-better-fantasy-football/'
                    },
                    {
                        title: 'NFC South Breakdown',
                        link: 'https://www.thefantasyfootballers.com/articles/nfc-south-divisional-breakdown-recap-for-2021-fantasy-football/'
                    }
                ],
                notes: '',
                ranks: [1, 1, 1]
            },
            {
                name: 'Dalvin Cook',
                team: 'MIN',
                position: 'RB',
                rank: 2,
                player_rank: 2,
                ht_wt:  '5\' 10", 210 lbs',
                drafted: 'Rd 2 (#41) - 2017',
                experience: '4 years',
                college: 'Florida State',
                bye: 7,
                blurb: 'Despite missing four games over the last two years, Cook has still managed to rack up 3,572 total yards and 30 touchdowns. He\'s still yet to play 16 games in his four-year career, but when he\'s on the field, he\'s a surefire RB1. In each of the final 12 games he played in 2020, he touched the ball a minimum of 17 times, including 24-plus touches in nine of them. The craziest part about his numbers, though, is the fact that he has 97 receptions for 880 yards over the last two years, but just one receiving touchdown, so there\'s room for even more of a ceiling.',
                link: 'https://www.thefantasyfootballers.com/fantasy/dalvin-cook/',
                img: 'https://cloud.thefantasyfootballers.com/images/web-profile/headshots/fdnobg18872.png',
                articles: [
                    {
                        title: 'Fantasy Footballers Top 10',
                        link: 'https://www.thefantasyfootballers.com/articles/the-fantasy-footballers-top-10-rb-rankings-for-2021/'
                    },
                    {
                        title: 'Underdog Fantasy',
                        link: 'https://www.thefantasyfootballers.com/dfs/underdog-fantasy-big-dog-best-ball-recap-with-borg-betz/'
                    },
                    {
                        title: 'DFS Strategy',
                        link: 'https://www.thefantasyfootballers.com/dfs/dfs-strategy-how-to-attack-the-rb-position-fantasy-football/'
                    }
                ],
                notes: '',
                ranks: [2, 2, 3]
            },
            {
                name: 'Derrick Henry',
                team: 'TEN',
                position: 'RB',
                rank: 3,
                player_rank: 3,
                ht_wt:  ' 6\' 3", 247 lbs',
                drafted: 'Rd 2 (#45) - 2016',
                experience: '5 years',
                college: 'Alabama',
                bye: 13,
                blurb: "The man just keeps getting better... Every year he's been in the league, we've watched his rushing yardage go up by at least 250 yards. I'm sure he peaked last year with 2,027 yards on the ground, but now going to a 17-game season, maybe I'm wrong. Henry should be in scoring position quite a bit this year knowing the Titans went out and acquired Julio Jones this offseason. There's no scenario where your offense gets worse in that equation. However, we don't know how the offense is going to look in 2021, as Arthur Smith (offensive coordinator the last two years) is gone. Still, with Jones and A.J. Brown on the perimeter, it's going to be tough for opponents to stack the box the way they have against Henry the last few years, so his efficiency may actually go up. There are certainly some variables to his projections, but Henry has proven that despite his lack of usage in the passing game, he's going to make up for it on the ground.",
                link: 'https://www.thefantasyfootballers.com/fantasy/derrick-henry/',
                img: 'https://cloud.thefantasyfootballers.com/images/web-profile/headshots/fdnobg17959.png',
                articles: [
                    {
                        title: 'Fantasy Footballers Top 10',
                        link: 'https://www.thefantasyfootballers.com/articles/the-fantasy-footballers-top-10-rb-rankings-for-2021/'
                    },
                    {
                        title: 'Underdog Fantasy',
                        link: 'https://www.thefantasyfootballers.com/dfs/underdog-fantasy-big-dog-best-ball-recap-with-borg-betz/'
                    },
                    {
                        title: 'DFS Strategy',
                        link: 'https://www.thefantasyfootballers.com/dfs/dfs-strategy-how-to-attack-the-rb-position-fantasy-football/'
                    }
                ],
                notes: '',
                ranks: [3, 3, 2]
            }
        ];

        let scriptText = '<script src="https://embed.clipcast.app/clipcastRankings.js"></script>';
        let cssText = '<link rel="stylesheet" type="text/css" href="https://embed.clipcast.app/clipcastRankings.css"/>';
        let divText = '<div id="clipcastEmbedPlayer" class="ABC123"></div>';

        return (
            <Grid item xs={12} align='center'>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                        >
                            <Typography variant={'h5'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Player Rankings Demo
                            </Typography>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Clipcast provides a way for websites to rank players and display those rankings anywhere
                                on their site. We have partered with the Fantasy Footballers to provide content and
                                information on players, including the ability to play clips from their podcast for
                                each player. Additionally, you are able to link your own podcast to your rankings page,
                                allowing clips from your podcast to be played for each player.
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                               To start, you create an account at clipcast.app, then log in. In the Dashboard, you can
                                can navigate to the Rankings page and rank your players. Multiple people are able to
                                rank players and they can be combined into one table. You can group your rankings by
                                position. Once your rankings have been setup in Clipcast, you can then add them to your
                                site.
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                To do this, you just include two lines into your website. These lines are below:
                            </Typography>

                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{scriptText}</code>
                            </Typography>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{cssText}</code>
                            </Typography>

                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                You then need to add a div element to each page you want to show the table in the place
                                of the table. You can do so with the below code. Note that you will need to get your
                                custom code from the Clipcast dashboard and overwrite the ABC123 below.
                            </Typography>
                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px", color: "rgb(120, 120, 120)"}}>
                                <code>{divText}</code>
                            </Typography>
                            <br/>

                            <br/>
                            <Typography variant={'p'} style={{textAlign: "center", marginBottom: "4px"}}>
                                That is it! Now you can include your rankings table on any page of your website. There
                                are customization options available in the Clipcast Dashboard to help brand the table
                                to your website. You can also specify which positions and data will be displayed, if
                                you want your custom clips included, and many more features. Create an account to get
                                started with your rankings today!
                            </Typography>

                        </Grid>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        <Grid
                            container
                            direction="column"
                        >
                            <Typography variant={'h5'} style={{textAlign: "center", marginBottom: "4px"}}>
                                Player Rankings Demo
                            </Typography>

                            <div className={classes.tableRoot}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Rank</TableCell>
                                            <TableCell align="left">Player (Team)</TableCell>
                                            <TableCell align="left">Eric (8/3)</TableCell>
                                            <TableCell align="left">Mike (8/6)</TableCell>
                                            <TableCell align="left">Scott (8/1)</TableCell>
                                            <TableCell align="left">Listen</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map(row => (
                                            <TableRow key={row.name}>
                                                <TableCell component="th" scope="row">
                                                    {row.rank}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <a href={row.link}>
                                                        {row.name} ({row.team} - {row.position})
                                                    </a>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={()=>{this.onPlayerChatClick(row)}}
                                                    >
                                                        <ChatIcon />
                                                    </IconButton>
                                                </TableCell>
                                                {row.ranks.map(rank => (
                                                    <TableCell key={rank} align="left">{rank}</TableCell>
                                                ))}
                                                <TableCell>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={()=>{this.onPlayerButtonClick(row.name)}}
                                                    >
                                                        <HeadsetIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </div>

                            <div id="clipcastEmbedPlayer"></div>

                            {this.state.selectedPlayer && <Dialog
                                fullWidth={true}
                                maxWidth="md"
                                open={this.state.selectedPlayer !== null}
                                onClose={this.onCloseDialog}
                            >
                                <DialogTitle id="alert-dialog-title">
                                    <Grid container direction="row">
                                        <div>
                                            <img className={classes.image} alt="" src={this.state.selectedPlayer.img} height={88}/>
                                        </div>
                                        <div style={{minWidth: "300px"}}>
                                            <Grid item className={classes.dialogBanner}>
                                                <h3 className={classes.dialogText}>{this.state.selectedPlayer.name}</h3>
                                            </Grid>
                                            <Grid container item className={classes.dialogSubBanner} direction="row" spacing={2}>
                                                <h5 className={classes.dialogSubText}>{this.state.selectedPlayer.team}: {this.state.selectedPlayer.position}</h5>
                                                <h5 className={classes.dialogSubText}>BYE {this.state.selectedPlayer.bye}</h5>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container>
                                        <Grid item container
                                              direction="column"
                                              justifyContent="space-between"
                                              xs={4}
                                              className={classes.statCard}
                                        >
                                            <Grid container direction="row">
                                                <h4 className={classes.greenText}>HT/WT</h4>
                                                <h5 className={classes.whiteText}>{this.state.selectedPlayer.ht_wt}</h5>
                                            </Grid>
                                            <Grid container direction="row">
                                                <h4 className={classes.greenText}>DRAFTED</h4>
                                                <h5 className={classes.whiteText}>{this.state.selectedPlayer.drafted}</h5>
                                            </Grid>
                                            <Grid container direction="row">
                                                <h4 className={classes.greenText}>COLLEGE</h4>
                                                <h5 className={classes.whiteText}>{this.state.selectedPlayer.college}</h5>
                                            </Grid>
                                            <Grid container direction="row">
                                                <h4 className={classes.greenText}>EXPERIENCE</h4>
                                                <h5 className={classes.whiteText}>{this.state.selectedPlayer.experience}</h5>
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={8}>
                                            <DialogContentText id="alert-dialog-description">
                                                Recent Articles
                                                {
                                                    this.state.selectedPlayer.articles.map(article => (
                                                        <div key={article.link}>
                                                            <a href={article.link}>{article.title}</a>
                                                        </div>
                                                    ))
                                                }
                                            </DialogContentText>
                                        </Grid>
                                        <Grid item container xs={8}>
                                            <DialogContentText id="alert-dialog-description">
                                                {this.state.selectedPlayer.blurb}
                                            </DialogContentText>
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                            </Dialog>}

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        );
    }

    componentDidMount () {
        const script = document.createElement("script");

        script.src = process.env.REACT_APP_ENV === "prod" ? "https://embed.clipcast.app/clipcastEmbedPlayer.js" : "https://test-embed.clipcast.app/clipcastEmbedPlayer.js";
        script.async = true;

        document.body.appendChild(script);
    }

    onPlayerButtonClick = (name) => {
        console.log(name)
        window.ClipcastEmbedPlayer.searchClips(name, {exclusivePodcastIds: ["FANFOOTBALLERS"]});
    }

    onPlayerChatClick = (player) => {
        this.setState({selectedPlayer: player});
    }

    onCloseDialog = () => {
        this.setState({selectedPlayer: null});
    }
}

export default withStyles(styles)(PlayerRankingsDemo);